<template>
  <div id="searchbox" class="searchbox dark:border-none">
    <div id="search" class="search">
      <form id="fakebox-form" @submit.prevent>
        <div id="fakebox-text" />
        <svg
          id="magnifying-glass"
          class="magnifying-glass"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="#5F6368"
        >
          <path
            d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
          />
        </svg>
        <input
          id="fakebox-input"
          v-model="search"
          v-autofocus-on-document-visible="inputFocus"
          type="text"
          autocomplete="off"
          class="form-control pr-5 font-roboto placeholder:text-weather_label dark:bg-radio_group_bg_color dark:text-radio_group_text_color_dark dark:placeholder:text-radio_group_text_color_dark focus:dark:bg-radio_group_bg_color dark:focus:text-radio_group_text_color_dark"
          tabindex="-1"
          :placeholder="$t('search_web_url')"
          @input="onChange"
          @keydown.down="onArrowDown"
          @keydown.up="onArrowUp"
          @keydown.enter="onEnter(arrowCounter)"
          @keydown.esc="() => (isOpen = false)"
          @focusout="handleClickOutside"
          @focusin="inputFocus"
        />
        <div v-if="enhancedSearchbox && showSearchProvider && !hideLogo" class="Frame-157">
          <span class="Powered-by text-onelaunch_dark_shades_100 dark:text-radio_group_text_color_dark">
            Powered by
          </span>
          <YahooLogo />
        </div>
        <span
          class="Paragraph14 text-onelaunch_dark_shades_400 dark:text-onelaunch_primary_shades_100"
          @mousedown="onEnter(arrowCounter)"
        >
          ↵
        </span>
        <div id="fakebox-cursor" />
        <div id="fakebox-btn" tabindex="1" />
      </form>
      <ul
        v-show="isOpen"
        id="searchbox-results"
        class="searchbox-results bg-onelaunch_ui_shades_50 dark:bg-radio_group_bg_color dark:text-radio_group_text_color_dark"
      >
        <li
          v-for="(result, i) in results"
          :key="i"
          class="searchbox-result font-roboto hover:bg-searchbox_results_bg active:bg-searchbox_results_bg dark:hover:bg-searchbox_results_bg_dark dark:active:bg-searchbox_results_bg_dark"
          :style="{ height: result.height }"
          :class="{ 'is-active': i == arrowCounter }"
          @mousedown="onEnter(i)"
          @keyup.enter="onEnter(i)"
        >
          <div><img :class="result.defaultImgProp" :src="result.image" /></div>
          <div class="searchbox-result-text">
            <span>{{ result.text }}</span>
            <br />
            <span class="searchbox-hint">
              {{ result.hint }}
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import { getConfig, sendMixpanelEvent } from '~/utils/host-browser-apis'
  import { getSuggestions } from '~/assets/js/searchsuggestions'
  import YahooLogo from '~/assets/icons/yahoo_logo.svg?skipsvgo'
  import * as HostBrowserApis from '~/utils/host-browser-apis'
  import useActiveSearchProvider from '~/composables/shift/useActiveSearchProvider'

  export default {
    name: 'SearchBox',
    components: {
      YahooLogo,
    },
    props: {
      items: {
        type: Array,
        required: false,
        default: () => [],
      },
      isAsync: {
        type: Boolean,
        required: false,
        default: false,
      },
      enhancedSearchbox: {
        type: Boolean,
        default: false,
      },
      showSearchProvider: {
        type: Boolean,
        default: true,
      },
      /** * HACK: please remove me after yahoo presentation */
      hideLogo: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['input'],
    data() {
      return {
        isOpen: false,
        results: [],
        search: '',
        isLoading: false,
        arrowCounter: -1,
        suggestUrl: '',
      }
    },
    watch: {
      items: function (value, oldValue) {
        if (value.length !== oldValue.length) {
          this.results = value
          this.isLoading = false
        }
      },
    },
    async mounted() {
      await this.init()

      if (this.enhancedSearchbox) {
        document.querySelector('#search').classList.remove('search')
        document.querySelector('#search').classList.add('search-new')

        document.querySelector('#magnifying-glass').classList.remove('magnifying-glass')
        document.querySelector('#magnifying-glass').classList.add('magnifying-glass-new')

        document.querySelector('.searchbox').style.padding = 0
        document.querySelector('#searchbox').style.position = 'relative'

        document.querySelector('#fakebox-input').placeholder = this.$t('search_web')
        document.querySelector('.searchbox-results').style.borderRadius = '0 0 6px 6px'

        document.querySelector('.searchbox-results').style.boxShadow = 'var(--search-results-box-shadow)'
      }
    },
    methods: {
      async onChange() {
        this.$emit('input', this.search)
        const fakeboxInput = document.getElementById('fakebox-input')

        if (this.isAsync) {
          this.isLoading = true
        } else if (this.search) {
          const searchTerms = this.search
          this.results = await getSuggestions(searchTerms, this.suggestUrl)

          if (this.results[0] === undefined) {
            this.isOpen = false
            fakeboxInput.style.borderRadius = this.enhancedSearchbox ? '6px' : '22px'
            return
          }

          this.isOpen = true

          fakeboxInput.style.borderRadius = this.enhancedSearchbox ? '6px 6px 0 0' : '22px 22px 0 0'
        } else {
          this.isOpen = false
          fakeboxInput.style.borderRadius = this.enhancedSearchbox ? '6px' : '22px'
        }
      },
      handleClickOutside() {
        document.getElementById('fakebox-input').style.borderRadius = this.enhancedSearchbox ? '6px' : '22px'
        this.isOpen = false
        this.arrowCounter = -1

        if (this.enhancedSearchbox) {
          document.querySelector('.search-new').style.border = 'var(--search-border)'
          document.querySelector('.Paragraph14').style.display = 'none'
          document.querySelector('.magnifying-glass-new').setAttribute('fill', 'var(--magnifying-glass)')
          const poweredByYahoo = document.querySelector('.Frame-157')
          if (poweredByYahoo) {
            poweredByYahoo.style.display = 'flex'
          }
        }
      },
      inputFocus() {
        if (this.enhancedSearchbox) {
          const poweredByYahoo = document.querySelector('.Frame-157')
          if (poweredByYahoo) {
            poweredByYahoo.style.display = 'none'
          }
          const searchbox = document.querySelector('.search-new')
          if (!searchbox) return

          searchbox.style.border = 'var(--search-border-focus)'

          document
            .querySelector('.magnifying-glass-new')
            .setAttribute('fill', 'var(--magnifying-glass-focus)')

          document.querySelector('.Paragraph14').style.display = 'block'
        }
      },
      onArrowDown() {
        if (this.arrowCounter < Object.keys(this.results).length - 1) {
          this.arrowCounter = this.arrowCounter + 1
          this.search = this.results[this.arrowCounter].text
        }
      },
      onArrowUp() {
        if (this.arrowCounter > 0) {
          this.arrowCounter = this.arrowCounter - 1
          this.search = this.results[this.arrowCounter].text
        }
      },
      async init() {
        try {
          const config = await getConfig()
          if (config !== undefined) {
            this.suggestUrl = config?.rich_suggest_url || config?.suggest_url || null
          }
        } catch (error) {
          this.suggestUrl = null
        }
      },
      async onEnter(index) {
        this.search = index !== -1 ? this.results[index].text : this.search
        this.isOpen = false

        document.getElementById('fakebox-input').style.borderRadius = this.enhancedSearchbox ? '6px' : '22px'

        const DEFAULT_SERP = 'https://search.yahoo.com/yhs/search?p={searchTerms}'
        const SEARCH_TERM = '{searchTerms}'

        if (this.search.length > 0) {
          const { url, isMonetized } = await HostBrowserApis.getSearchUrl(this.search, {
            fallbackUrl: DEFAULT_SERP,
          })
          const isNewMonetizedApiAvailable = isMonetized !== undefined

          if (isNewMonetizedApiAvailable === false) {
            await sendMixpanelEvent('query', { location: 'ntp' })
          }

          if (isNewMonetizedApiAvailable) {
            const activeSearchProvider = await useActiveSearchProvider()
            if (isMonetized) {
              await sendMixpanelEvent('query', {
                location: 'ntp',
                monetized: true,
                active_search_provider: activeSearchProvider,
              })
            } else {
              await sendMixpanelEvent('QueryNonMonetized_Submitted', {
                location: 'ntp',
                monetized: false,
                active_search_provider: activeSearchProvider,
              })
            }
          }

          window.open(url)
          this.search = ''
        }
      },
    },
  }
</script>

<style lang="scss">
  @import 'bootstrap/scss/bootstrap';
  @import '~/assets/stylesheets/common';

  body {
    font-family:
      Inter,
      Roboto,
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Helvetica,
      Arial,
      sans-serif,
      serif,
      monospace;
  }
</style>

<style lang="scss">
  .search {
    max-width: 561px;
    position: relative;
    width: 100%;
  }

  .search input {
    height: 44px;
    text-indent: 25px;
    padding-inline-start: 30px;
    box-shadow: 0 1px 6px 0 rgb(32, 33, 36, 28%);
    border-radius: 22px;
  }

  .search input::placeholder {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.06;
    letter-spacing: -0.1px;
    color: #616161;
  }

  .search-new {
    position: relative;
    width: inherit;
    border: var(--search-border);
    border-radius: 6px;
    background-color: $onelaunch-ui-shades-100;
  }

  .search-new input {
    height: 48px;
    text-indent: 25px;
    padding-inline-start: 30px;
    border-radius: 6px;
    border: 0;
    background-color: $onelaunch-ui-shades-100;
  }

  .search-new input::placeholder {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.06;
    letter-spacing: -0.1px;
    color: #616161;
  }

  .search-new:hover {
    background-color: $onelaunch-ui-shades-50;
  }

  .search-new:hover input {
    background-color: $onelaunch-ui-shades-50;
  }

  .searchbox {
    --search-border: solid 1px #c7cbd0;
    --search-border-focus: solid 2px #005eb7;
    --magnifying-glass: #2f3438;
    --magnifying-glass-focus: #005eb7;
    --search-results-box-shadow: 0 -1px 0 0 #fff, -2px 0 0 0 #005eb7, 2px 0 0 0 #005eb7, 0 2px 0 0 #005eb7;

    display: flex;
    justify-content: center;
    width: 100%;
    padding: 16px 0;

    &--fixed {
      background-color: #fff;
      top: 0;
      z-index: 100;
      position: fixed;
      border-bottom: solid 1px #eaecee;
    }
  }

  .magnifying-glass,
  .magnifying-glass-new {
    position: absolute;
    left: 20px;
    top: 12px;
    transform: scale(0.9);
  }

  .form-control {
    border: 0;
  }

  input.form-control:focus {
    box-shadow: 0 1px 6px #20212447;
    border-color: white;
  }

  .searchbox-results {
    overflow: auto;
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 8px;
    box-shadow: 0 6px 6px 0 rgb(32, 33, 36, 28%);
    margin-top: -6px;
    padding-top: 6px;
    width: 100%;
    left: 0;
    text-align: start;
    border-radius: 0 0 22px 22px;
    position: absolute;
    z-index: 9999;
  }

  .searchbox-result {
    cursor: pointer;
    padding: 2px 16px;
    margin: 0;
    margin-left: -32px;
    white-space: nowrap;
    overflow: hidden;
    height: 44px;
    display: flex;
    align-items: center;
  }

  .searchbox-result-img {
    object-fit: contain;
  }

  .searchbox-result-text {
    padding-left: 10px;
  }

  .searchbox-hint {
    color: gray;
    float: left;
    font-size: 12px;
    vertical-align: bottom;
    width: 100%;
  }

  .searchbox-default-icon {
    height: 20px;
    width: 20px;
    margin-right: 10px;
    margin-left: 2px;
    object-fit: contain;
  }

  .non-searchbox-default-icon {
    height: 32px;
    width: 32px;
    object-fit: contain;
    border-radius: 5px;
  }

  .searchbox-suggestions-searchText {
    border-radius: 0 0 20px 20px;
  }

  #fakebox-cursor {
    visibility: inherit;
    animation: blink 1.3s step-end infinite;
  }

  @media (max-width: 1400px) {
    .search {
      max-width: 393px;

      input {
        height: 31px;
        text-indent: 15px;

        &::placeholder {
          font-size: 14px;
        }
      }
    }

    .magnifying-glass {
      left: 15px;
      top: 4px;
      transform: scale(0.7);
    }

    #fakebox-form #fakebox-cursor {
      bottom: 9px;
      left: 45px;
      top: 9px;
    }
  }

  .Frame-157 {
    flex-grow: 0;
    display: flex;
    align-items: center;
    gap: 4px;
    padding-right: 12px;
    padding-left: 10px;
  }

  .Powered-by {
    width: 10ch;
    height: 24px;
    flex-grow: 0;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
  }

  #fakebox-form {
    display: flex;
    align-items: center;
  }

  .Paragraph14 {
    display: none;
    width: 14px;
    height: 24px;
    flex-grow: 0;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    position: absolute;
    right: 12px;
    cursor: pointer;
  }

  .dark .searchbox {
    --search-border: none;
    --search-border-focus: solid 2px #98ecfe;
    --magnifying-glass: #9d9ea1;
    --magnifying-glass-focus: #98ecfe;
    --search-results-box-shadow: 0 -1px 0 0 #000, -2px 0 0 0 #98ecfe, 2px 0 0 0 #98ecfe, 0 2px 0 0 #98ecfe;
  }

  .dark .search-new {
    background-color: #0d0e10;
  }

  .dark .searchbox--fixed {
    background-color: #26272e;
  }

  .dark .search-new:hover {
    background-color: #0d0e10;
  }

  .dark .search-new:hover input {
    background-color: #0d0e10;
  }

  .dark input.form-control {
    border-color: transparent;
  }

  .dark input.form-control:focus {
    border-color: transparent;
  }
</style>
